export const getCSRFToken = () => {
  const csrfCookie = document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith("csrftoken="));
  if (csrfCookie) {
    return csrfCookie.split("=")[1];
  }
  return null;
};

export const getLocaleDate = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleString();
};

export const getDayAndMonth = (date) => {
  const newDate = new Date(date);
  const day = newDate.getUTCDate();
  const month = newDate.getUTCMonth() + 1;
  return `${day}/${month}`;
};
